<template>
  <div class="box-body">
    <ul class="timeline timeline-inverse" v-for="(bonuses, date) in employee_bonuses">
      <li class="time-label">
        <span class="bg-blue">
          {{ date }}
        </span>
      </li>
      <li>
        <div class="timeline-item">
          <h3 class="timeline-header" v-for="bonus_item in bonuses">
            <strong style="font-weight: 600;">
              Штрафных начислений по итогам месяца: {{ bonus_item.bonuses_amount }}$
            </strong>
          </h3>
        </div>
      </li>
      <li>
        <i class="fa fa-clock-o bg-gray"></i>
      </li>
    </ul>
    <div class="panel panel-default" v-if="employee_bonuses.length === 0">
      <div class="panel-body text-center text-muted mt-5 mb-5">
        Нет элементов для отображения
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'employee-bonuses',

  props: {
    employeeId: {
      type: [String, Number],
      required: true,
    },
  },

  data: () => ({
    employee_bonuses: [],
  }),

  async created() {
    try {
      const employee_bonuses_response = await API.apiClient.get(
          '/employee/' + this.employeeId + '/bonuses'
      );

      this.employee_bonuses = employee_bonuses_response.data;
      console.log('employee-bonuses', this.employee_bonuses);
    } catch (error) {
      errorHandler(error);
    }
  },
}
</script>

<style scoped>

</style>
